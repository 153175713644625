
import Vue, { PropType } from "vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import {
  CustomMappingValue,
  Mapping,
  Mappings,
} from "@/modules/connectorMapper/api/getConnectorMapper";

export default Vue.extend({
  name: "DynamicMappings",
  components: { KTextField },
  props: {
    value: {
      type: Array as PropType<Mappings>,
      default: () => [{ customMappingValues: [{}, {}] }],
    },
  },
  data: () => ({
    internalMappings: [] as Mappings,
  }),
  watch: {
    internalMappings: {
      handler() {
        this.addNewMappingIfLastNotEmpty();
        this.internalMappings.forEach((mapping, index) => {
          this.addNewCustomMappingValueIfLastNotEmpty(index);
        });

        this.emitMappings();
      },
      deep: true,
    },
    value: {
      handler() {
        this.internalMappings = this.value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    deleteCustomMappingValue(
      internalMappingIndex: number,
      valueIndex: number
    ): void {
      this.internalMappings[internalMappingIndex].customMappingValues.splice(
        valueIndex,
        1
      );
    },
    deleteInternalMapping(internalMappingIndex: number): void {
      this.internalMappings.splice(internalMappingIndex, 1);
    },
    addNewMapping(): void {
      this.internalMappings.push({ customMappingValues: [{}, {}] });
    },
    addNewMappingIfLastNotEmpty(): void {
      const lastMapping: Mapping =
        this.internalMappings[this.internalMappings.length - 1];

      if (
        !lastMapping ||
        lastMapping.dwhField ||
        lastMapping.query ||
        lastMapping.customMappingValues.some(
          (value) => value.condition || value.translation
        )
      ) {
        this.addNewMapping();
      }
    },
    addNewCustomMappingValue(mappingIndex: number): void {
      this.internalMappings[mappingIndex].customMappingValues.push({});
    },
    addNewCustomMappingValueIfLastNotEmpty(mappingIndex: number): void {
      const lastCustomMappingValue: CustomMappingValue =
        this.internalMappings[mappingIndex].customMappingValues[
          this.internalMappings[mappingIndex].customMappingValues.length - 1
        ];
      if (
        !lastCustomMappingValue ||
        lastCustomMappingValue.condition ||
        lastCustomMappingValue.translation
      ) {
        this.addNewCustomMappingValue(mappingIndex);
      }
    },
    emitMappings(): void {
      this.$emit("update:internalMappings", this.internalMappings);
    },
  },
});
