import { get } from "@/application/api";
import { AxiosPromise } from "axios";
import { Mappings } from "@/modules/connectorMapper/api/types";

export interface ConnectorMapperResponse {
  id: number;
  settings: unknown;
  mappings: Mappings;
}

export function getConnectorMapper(
  connectorId: number,
  mapperId: number
): AxiosPromise<{ data: ConnectorMapperResponse }> {
  return get(`connector/${connectorId}/mapper/${mapperId}`);
}
