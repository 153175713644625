
import Vue from "vue";
import {
  ConnectorMapperResponse,
  getConnectorMapper,
} from "@/modules/connectorMapper/api/getConnectorMapper";
import { updateConnectorMapper } from "@/modules/connectorMapper/api/updateConnectorMapper";
import DynamicInput from "@/modules/connectorMapper/components/DynamicInput.vue";
import KForm from "@/components/KForm.vue";
import DynamicMappings from "@/modules/connectorMapper/components/DynamicMappings.vue";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";

export default Vue.extend({
  name: "ConnectorMapperForm",
  components: { KFieldGroup, DynamicMappings, KForm, DynamicInput },
  data: () => ({
    connectorMapper: undefined as ConnectorMapperResponse | undefined,
    settings: {},
    isLoading: false,
    isValid: false,
  }),
  created() {
    this.getConnectorMapper();
  },
  methods: {
    async getConnectorMapper() {
      const response = await getConnectorMapper(
        parseInt(this.$route.params.connectorId),
        parseInt(this.$route.params.mapperId)
      );
      this.connectorMapper = response.data.data;
      this.connectorMapper.settings.forEach((setting) => {
        this.settings[setting.id] = setting.value;
      });
    },
    async handleUpdate() {
      await updateConnectorMapper(this.connectorMapper.id, {
        settings: this.settings,
        mappings: this.connectorMapper?.mappings,
      });

      await this.getConnectorMapper();
    },
  },
});
