
import Field from "@/components/crud/fields/FieldMixin.vue";
import { VCheckbox, VTextField, VSelect } from "vuetify/lib";
import Vue from "vue";

export default Vue.extend({
  name: "DynamicInput",
  mixins: [Field],
  props: {
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  computed: {
    items() {
      if (!this.options) return [];
      return Object.entries(this.options).map(([value, text]) => ({
        value,
        text,
      }));
    },
    dynamicComponent() {
      const map = {
        checkbox: VCheckbox,
        password: VTextField,
        text: VTextField,
        select: VSelect,
      };
      return map[this.type];
    },
    dynamicComponentProps() {
      return {
        ...this.$attrs,
        ...(this.type === "select" && { items: this.items }),
        ...(this.type === "checkbox" && {
          inputValue: this.$attrs.value,
          trueValue: "1",
          falseValue: "0",
        }),
      };
    },
    dynamicComponentListeners() {
      return {
        ...this.$listeners,
        ...(this.type === "checkbox" && {
          change: (event) => {
            this.$emit("input", event || false);
          },
        }),
      };
    },
  },
});
