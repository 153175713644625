import { put } from "@/application/api";
import { Mappings } from "@/modules/connectorMapper/api/types";

export function updateConnectorMapper(
  connectorMapperId: number,
  data: { settings: unknown; mappings: Mappings }
) {
  data.mappings = data.mappings.filter(
    (mapping) => mapping.dwhField || mapping.query
  );

  data.mappings.forEach((mapping) => {
    mapping.customMappingValues = mapping.customMappingValues.filter(
      (mappingValue) => {
        return mappingValue.condition || mappingValue.translation;
      }
    );
  });

  return put(`connector-mapper/${connectorMapperId}`, data);
}
